<template>
  <div>
    <v-dialog v-model="show_dialog" width="400">
      <div class="neumorphism-pressed pa-4 pt-6">
        <div class="text-center px-8 text-body-1">
          Are you sure you want to delete
          <span class="font-weight-black">{{ title }}</span> {{ delete_type }}?
        </div>
        <div class="d-flex justify-end pt-4 pr-3 pb-0">
          <button class="no-btn mr-3" @click="show_dialog = false">
            <div class="no-icon">
              No
            </div>
          </button>
          <button
            @click="delete_type == 'topic' ? deleteTopic() : deleteTemplate()"
            class="yes-btn"
          >
            Yes
          </button>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show_dialog: false,
      btn_loader: false,
      delete_type: "",
      title: "",
      id: null,
    };
  },
  methods: {
    async openDeleteConfirmation(type, value) {
      this.show_dialog = true;
      this.delete_type = type;
      if (type == "template") {
        this.title = value.title;
        this.id = value.id;
        console.log("template", value);
      } else if (type == "topic") {
        this.title = value.topic_name;
        this.id = value.id;
        console.log("topic", value.id);
      }
    },
    deleteTopic() {
      const self = this;

      self.btn_loader = true;
      let lookup_key = "";
      let params = {
        topic_id: this.id,
      };
      const successHandler = () => {
        self.show_dialog = false;
        self.$emit("update");
      };
      const finallyHandler = () => {
        self.btn_loader = false;
      };
      self.DELETE_request(
        self,
        self.$urls.FCM_TOPIC,
        lookup_key,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    deleteTemplate() {
      const self = this;

      self.btn_loader = true;
      let lookup_key = "";
      let params = {
        notification_id: this.id,
      };
      const successHandler = () => {
        self.show_dialog = false;
        self.$emit("update");
      };
      const finallyHandler = () => {
        self.btn_loader = false;
      };
      self.DELETE_request(
        self,
        self.$urls.FCM_NOTIFICATION,
        lookup_key,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
  },
};
</script>
<style scoped>
.no-btn {
  padding: 5px 10px;
  border-radius: 20px;
  background: #eef5fb !important;
  border: 1px solid #a9d7ff57 !important;

  box-shadow: 6px 6px 12px #dbe1e7, -6px -6px 12px #ffffff !important;
}
.no-icon {
  /* font-size: 72px; */
  font-weight: 900;
  background-color: #ffd641;
  background-image: linear-gradient(90deg, #ffd641 0%, #ff003c 100%);

  /* background-color: #ffe53b; */
  /* background-image: linear-gradient(147deg, #ffe53b 0%, #ff2525 74%); */

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.no-btn:hover {
  background: #e3edf7;
  box-shadow: inset 6px 6px 12px #dbe1e7, inset -6px -6px 12px #ffffff !important;
}
.yes-btn {
  padding: 5px 10px;
  border-radius: 20px;
  color: #fff;
  font-weight: 800;
  background: hsla(4, 84%, 49%, 1);

  background: linear-gradient(
    90deg,
    hsla(4, 84%, 49%, 1) 0%,
    hsla(37, 89%, 52%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(4, 84%, 49%, 1) 0%,
    hsla(37, 89%, 52%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(4, 84%, 49%, 1) 0%,
    hsla(37, 89%, 52%, 1) 100%
  );

  /* box-shadow: 0px 0px 12px hsla(294, 100%, 55%, 1) !important; */

  /* box-shadow: 6px 6px 12px #b5b5b5, -6px -6px 12px #ffffff; */
  box-shadow: 6px 6px 12px #7e7e7ea9, -6px -6px 6px #ffffff !important;
  /* filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#40C9FF", endColorstr="#E81CFF", GradientType=1 ); */
}
.yes-btn:hover {
  box-shadow: 6px 6px 12px #dbe1e7, -6px -6px 12px #ffffff !important;
}
</style>
